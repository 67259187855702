import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export const PaySuccess = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const botId = queryParams.get('v') || '-';
    const background = queryParams.get('b') || 'e4d9ec';
    const textColorTitle = queryParams.get('t1') || '0A1172';
    const textColorSubtitle = queryParams.get('t2') || '4A4A4A';
    const redirectUrl = queryParams.get('r');
    const language = queryParams.get('lang') || 'en';
    // For GTM:
    const currency = queryParams.get('curr') ? queryParams.get('curr').toUpperCase() : 'USD';
    const value = parseFloat(queryParams.get('val')) || 0;
    const fbclid = queryParams.get('fbclid') || localStorage.getItem('fbclid');
    const utm_source = queryParams.get('utm_source') || localStorage.getItem('utm_source');
    const utm_medium = queryParams.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_campaign = queryParams.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_term = queryParams.get('utm_term') || localStorage.getItem('utm_term');
    const utm_content = queryParams.get('utm_content') || localStorage.getItem('utm_content');
    // This useEffect is used for sending the data to GTM
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'purchase',
            transactionId: `${Date.now()}-${Math.random()}`,
            transactionValue: value,
            currency,
            fbclid,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
            language
        });
    }, [
        value,
        currency,
        fbclid,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        language
    ]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://app.aminos.ai/js/chat_plugin_ghl.js`;
        script.setAttribute('data-bot-id', botId);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [botId]);

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [redirectUrl]);

    return (
        <>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>{language === 'lt' ? 'Ačiū. Užsakymas sėkmingas' : 'Thank You. Payment successful.'}</title>
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
                <style type="text/css">{`
                    body {
                        background-color: #${background};
                        font-family: 'Roboto', sans-serif;
                    }
                `}</style>
            </Helmet>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 style={{color: `#${textColorTitle}`, fontSize: '32px', textAlign: 'center', maxWidth: 600}}>
                    {language === 'lt' ? `Ačiū už mokėjimą MB Skydis. Užsakymas sėkmingas.` : `Thank you for your payment to MB Skydis. Your transaction was successful.`}
                </h1>
                <h2 style={{color: `#${textColorSubtitle}`, fontSize: '24px', textAlign: 'center', maxWidth: 600}}>
                    {language === 'lt' ?
                        <span>
                            Per ateinančias keletą darbo dienų mes sukursime ir pritaikysime Jūsų verslui virtualų asistentą
                            ir susisieksime el. paštu, kad galėtumėte išbandyti.
                        </span> :
                        <span>
                            We are now initiating the creation of your customized virtual assistant and will contact you
                            within the next couple of business days to provide access to your tailored solution for you to try.
                        </span>
                    }
                </h2>
            </div>
        </>
    );
};
