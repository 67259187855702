import {Helmet} from "react-helmet";
import React from "react";

export const VAProForm = () => {
    return (
        <>
            <Helmet>
                <title>MB Skydis Virtual Assistant (Pro)</title>
                <meta name="description" content="Revolutionize Your Business with AI Automation"/>
                <style type="text/css">{`
                    body {
                        background-color: #e4d9ec;
                        font-family: 'Roboto', sans-serif;
                    }
                `}</style>
            </Helmet>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}>
                <iframe
                    id="gform"
                    title="MB Skydis Virtual Assistant (Pro)"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSccqbrwf57P0N3AwWOcXD6rNP40T3on-3nZpF4pdnCOKyUfNw/viewform?embedded=true"
                    width="640" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
            </div>
        </>
    );
};
