import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { HomePage } from "./HomePage/HomePage";
import { HomePageLt } from "./HomePageLt/HomePageLt";
import { VirtualusAsistentas } from "./VirtualusAsistentas/VirtualusAsistentas";
import { VADemo } from "./VADemo/VADemo";
import { AutomationForm } from "./AutomationForm/AutomationForm";
import { AutomationFormLt } from "./AutomationFormLt/AutomationFormLt";
import { VAForm } from "./VAForm/VAForm";
import { VAForma } from "./VAForma/VAForma";
import { VirtualAssistant } from "./VirtualAssistant/VirtualAssistant";
import { Redirector } from "./Redirector/Redirector";
import { VAProForm } from "./VAProForm/VAProForm";
import { VABasicForm } from "./VABasicForm/VABasicForm";
import TagManager from 'react-gtm-module';
import Pay from "./Pay/Pay";
import {PaySuccess} from "./PaySuccess/PaySuccess";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import {VALimitedFreeForm} from "./VALimitedFreeForm/VALimitedFreeForm";
import {LimitedTimeOffer} from "./LimitedTimeOffer/LimitedTimeOffer";
import InfluencerROICalculator from "./Tools/InfluencerROICalculator/InfluencerROICalculator";

const tagManagerArgs = {
    gtmId: 'GTM-M4CT7XK9'
};

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname + location.search;
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pagePath: currentPath,
            },
        });
    }, [location]);
}

const TrackPageView = () => {
    usePageViews();
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const fbclid = queryParams.get('fbclid');
        const utm_source = queryParams.get('utm_source');
        const utm_medium = queryParams.get('utm_medium');
        const utm_campaign = queryParams.get('utm_campaign');
        const utm_term = queryParams.get('utm_term');
        const utm_content = queryParams.get('utm_content');
        const r = queryParams.get('r');
        if (fbclid) {
            localStorage.setItem('fbclid', fbclid);
        }
        if (utm_source) {
            localStorage.setItem('utm_source', utm_source);
        }
        if (utm_medium) {
            localStorage.setItem('utm_medium', utm_medium);
        }
        if (utm_campaign) {
            localStorage.setItem('utm_campaign', utm_campaign);
        }
        if (utm_term) {
            localStorage.setItem('utm_term', utm_term);
        }
        if (utm_content) {
            localStorage.setItem('utm_content', utm_content);
        }
        if (r !== null) {
            localStorage.setItem('r', r);
        } else {
            localStorage.removeItem('r');
        }
    })
    return null;
};
// Main component
const App = () => {
    TagManager.initialize(tagManagerArgs);
    return (
        <Router>
            <TrackPageView />
            <Routes>
                <Route path="/influencer-marketing-roi-calculator" element={<InfluencerROICalculator />} />
                <Route path="/redirect/:url" element={<Redirector />} />
                <Route path="/pay" element={<Pay />} />
                <Route path="/pay/success" element={<PaySuccess />} />
                <Route path="/pay/cancel" element={<VirtualAssistant />} />
                <Route path="/lt" element={<HomePageLt />} />
                <Route path="/virtual-assistant" element={<VirtualAssistant />} />
                <Route path="/ai" element={<VirtualAssistant />} />
                <Route path="/limited-time-offer" element={<LimitedTimeOffer />} />
                <Route path="/va-form" element={<VAForm />} />
                <Route path="/va-pro-form" element={<VAProForm />} />
                <Route path="/va-basic-form" element={<VABasicForm />} />
                <Route path="/va-limited-free-form" element={<VALimitedFreeForm />} />
                <Route path="/va-forma" element={<VAForma />} />
                <Route path="/automation-form" element={<AutomationForm />} />
                <Route path="/automatizavimas-forma" element={<AutomationFormLt />} />
                <Route path="/virtualus-asistentas" element={<VirtualusAsistentas />} />
                <Route path="/va-demo" element={<VADemo />} />
                <Route path="/demo/:id" element={<VADemo />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/" element={<HomePage />} />
                <Route path="*" element={<VirtualAssistant />} />
            </Routes>
        </Router>
    );
};

export default App;
