import {Helmet} from "react-helmet";
import React, { useEffect } from "react";

export const VAForm = () => {
    useEffect(() => {
        let load = 0;

        const handleIframeLoad = () => {
            load++;
            // 3rd reload is a submit
            if (load > 2) {
                const fbclid = localStorage.getItem('fbclid');
                const utm_source = localStorage.getItem('utm_source');
                const utm_medium = localStorage.getItem('utm_medium');
                const utm_campaign = localStorage.getItem('utm_campaign');
                const utm_term = localStorage.getItem('utm_term');
                const utm_content = localStorage.getItem('utm_content');

                // Construct the URL with all the parameters
                let redirectUrl = 'https://mbskydis.com/pay?t=149|49&curr=usd&lang=en&val=198';
                const params = new URLSearchParams();

                if (fbclid) params.append('client_reference_id', fbclid);
                if (utm_source) params.append('utm_source', utm_source);
                if (utm_medium) params.append('utm_medium', utm_medium);
                if (utm_campaign) params.append('utm_campaign', utm_campaign);
                if (utm_term) params.append('utm_term', utm_term);
                if (utm_content) params.append('utm_content', utm_content);

                if (params.toString()) {
                    redirectUrl += '&' + params.toString();
                }

                // Push the redirect event to the data layer before redirecting
                const parentDataLayer = window.parent.dataLayer || window.dataLayer || [];
                parentDataLayer.push({
                    'event': 'redirect_after_form',
                    'redirect_to': redirectUrl,
                    'fbclid': fbclid,
                    'utm_source': utm_source,
                    'utm_medium': utm_medium,
                    'utm_campaign': utm_campaign,
                    'utm_term': utm_term,
                    'utm_content': utm_content
                });

                setTimeout(() => {
                    window.top.location.href = redirectUrl;
                }, 100); // Delaying the redirect slightly

            }
        };

        const iframe = document.getElementById('gform');
        if (iframe) {
            iframe.onload = handleIframeLoad;
        }

        // Cleanup function to remove the event listener
        return () => {
            if (iframe) {
                iframe.onload = null;
            }
        };
    }, []); // Empty array means this effect runs only once after the initial render

    return (
        <>
            <Helmet>
                <title>MB Skydis Virtual Assistant Form</title>
                <meta name="description" content="Revolutionize Your Business with AI Automation"/>
                <style type="text/css">{`
                    body {
                        background-color: #e4d9ec;
                        font-family: 'Roboto', sans-serif;
                    }
                `}</style>
            </Helmet>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}>
                <iframe
                    id="gform"
                    title="MB Skydis Virtual Assistant Form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSf8743I1N1LxkQt5ne_bqvgMOG9B2EHufrmQT9ZuqN8N9oabA/viewform?embedded=true"
                    width="640" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
            </div>
        </>
    );
};
