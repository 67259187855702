import {Helmet} from "react-helmet";
import React from "react";

export const AutomationFormLt = () => {
    return (
        <>
            <Helmet>
                <title>MB Skydis automatizavimo sprendimai verslui panaudojant dirbtinį intelektą</title>
                <meta name="description" content="Suteikite daugiau informacijos apie Jūsų verslą ir mes su Jumis susisieksime."/>
                <style type="text/css">{`
                    body {
                        background-color: #e4d9ec;
                        font-family: 'Roboto', sans-serif;
                    }
                `}</style>
            </Helmet>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}>
                <iframe
                    title="AI Automation Agency"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdH4XcM7Q-6FSu39H2RXsXS02s9OkP3Ds07vKZWPFQvm5Clkw/viewform?embedded=true"
                    width="640" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
            </div>
        </>
    );
};