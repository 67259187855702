import {Helmet} from "react-helmet";
import React from "react";

export const VALimitedFreeForm = () => {
    return (
        <>
            <Helmet>
                <title>MB Skydis Virtual Assistant (Limited time offer)</title>
                <meta name="description" content="Revolutionize Your Business with AI Automation"/>
                <style type="text/css">{`
                    body {
                        background-color: #e4d9ec;
                        font-family: 'Roboto', sans-serif;
                    }
                `}</style>
            </Helmet>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}>
                <iframe
                    id="gform"
                    title="MB Skydis Virtual Assistant (Free - limited time offer)"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfJLuhOFxbV0ZJ_7tN_unxMzvmiJKm7FNF4R4mSsqbS2F3H5g/viewform?embedded=true"
                    width="640" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
            </div>
        </>
    );
};
