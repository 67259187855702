import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import './HomePageLt.css';

const adjustIframeHeight = () => {
    // Directly setting the style of the iframe by targeting its title for simplicity
    const iframe = document.querySelector('iframe[id="skydis"]');
    if (iframe) {
        // This adjusts the height to the inner height of the window, accounting for mobile toolbars
        iframe.style.height = `${window.innerHeight}px`;
    }
};

export const HomePageLt = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Listen for resize events to adjust the iframe height accordingly
        window.addEventListener('resize', adjustIframeHeight);

        // Initial adjustment when the component mounts
        adjustIframeHeight();

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', adjustIframeHeight);
    }, []);

    const handleIframeLoad = () => {
        setIsLoading(false); // Set loading to false when iframe is loaded
        adjustIframeHeight(); // Adjust height right after it loads
    };

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png"
                      href="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1641938162703x160586947454074660%2Fyep-icon-2%25402x.png?w=128&h=&auto=compress&dpr=1&fit=max"/>
                <meta name="description" content="Automatizuokite savo verslą pasitelkdami dirbtinį intelektą"/>
                <link rel="canonical" href="https://mbskydis.com/lt"/>
                <meta property="og:title" content="Automatizuokite savo verslą pasitelkdami dirbtinį intelektą"/>
                <meta name="twitter:title" content="Automatizuokite savo verslą pasitelkdami dirbtinį intelektą"/>
                <meta property="og:site_name" content="MB Skydis"/>
                <meta name="twitter:site_name" content="MB Skydis"/>
                <meta property="og:description" content="Automatizuokite savo verslą pasitelkdami dirbtinį intelektą"/>
                <meta name="twitter:description" content="Automatizuokite savo verslą pasitelkdami dirbtinį intelektą"/>
                <link rel="image_src"
                      href="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1712302086749x662896078816560300%2Fmb-skydis---ai-automation-solutions-1%2520-%2520social.png?w=&h=&auto=compress&dpr=1&fit=max"/>
                <meta property="og:image"
                      content="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1712302086749x662896078816560300%2Fmb-skydis---ai-automation-solutions-1%2520-%2520social.png?w=&amp;h=&amp;auto=compress&amp;dpr=1&amp;fit=max"/>
                <meta name="twitter:image:src"
                      content="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1712302086749x662896078816560300%2Fmb-skydis---ai-automation-solutions-1%2520-%2520social.png?w=&amp;h=&amp;auto=compress&amp;dpr=1&amp;fit=max"/>
                <meta property="og:url" content="https://mbskydis.com/lt"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div style={{width: '100%', height: '100vh', position: 'relative'}}>
                {isLoading && (
                    <div className="loader-container">
                        <div className="loader"></div>
                        <p>Loading...</p>
                    </div>
                )}
                <iframe
                    id="skydis"
                    src="https://yep.so/p/mb-skydis---ai-automation-solutions-1"
                    title="Automatizavimas panaudojant dirbtinį intelektą"
                    style={{width: '100%', height: '100%', border: 'none'}}
                    onLoad={handleIframeLoad}
                ></iframe>
            </div>
        </>
    );
};