import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy for MB Skydis</h1>
            <p>Last updated: February 7, 2024</p>
            <p>Welcome to MB Skydis. This Privacy Policy describes how we collect, use, process, and share your
                information, including personal information, in conjunction with your access to and use of our custom AI
                chatbot virtual assistant services.</p>

            <h2>1. Information We Collect</h2>
            <h3>1.1 Interaction Data</h3>
            <p>We collect and store information that you input while interacting with our virtual assistants. This
                includes text or voice messages and interaction logs.</p>
            <h3>1.2 Technical Data</h3>
            <p>We may collect information about the device and software you use to communicate with the virtual
                assistant, including IP address, device type, operating system, and browser type.</p>
            <h3>1.3 Cookies and Similar Technologies</h3>
            <p>We use cookies and similar tracking technologies to track the activity on our service and hold certain
                information.</p>

            <h2>2. How We Use Your Information</h2>
            <ul>
                <li>To provide, maintain, and improve our services, including to monitor the usage of our service.</li>
                <li>To manage your requests: attending and managing your requests to us.</li>
                <li>For customer support, including resolving issues.</li>
                <li>For compliance purposes, including enforcing our Terms of Service, or other legal rights.</li>
            </ul>

            <h2>3. Sharing and Disclosure of Information</h2>
            <p><strong>Service Providers:</strong> We may share your information with service providers who perform
                services on our behalf, such as data hosting and software and service maintenance providers.</p>
            <p><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in
                response to valid requests by public authorities (e.g., a court or a government agency).</p>

            <h2>4. Data Security</h2>
            <p>We strive to use commercially acceptable means to protect your personal information, but remember that no
                method of transmission over the Internet, or method of electronic storage is 100% secure.</p>

            <h2>5. International Transfers</h2>
            <p>Your information, including personal information, may be transferred to — and maintained on — computers
                located outside of your state, province, country, or other governmental jurisdiction where the data
                protection laws may differ from those from your jurisdiction.</p>

            <h2>6. Your Rights</h2>
            <p>You have certain data protection rights. You can request to access, correct, update, or request deletion
                of your personal information. You can also object to processing of your personal information, ask us to
                restrict processing of your personal information, and request portability of your personal
                information.</p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on this page.</p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at info [at] mbskydis [dot]
                com</p>
        </div>
    );
}

export default PrivacyPolicy;
