import React, {useEffect, useState} from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import './Pay.css';
import {Helmet} from "react-helmet";

const Pay = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const payType = params.get('t');
        const curr = params.get('curr');
        const lang = params.get('lang');
        const val = params.get('val');
        setValue(val);

        // Retrieve marketing params once from localStorage
        const marketingParams = {
            fbclid: localStorage.getItem('fbclid'),
            utm_source: localStorage.getItem('utm_source'),
            utm_medium: localStorage.getItem('utm_medium'),
            utm_campaign: localStorage.getItem('utm_campaign'),
            utm_term: localStorage.getItem('utm_term'),
            utm_content: localStorage.getItem('utm_content'),
            r: localStorage.getItem('r')
        };

        // Building query parameters for Lambda request including payType
        const lambdaQueryParams = {
            payType,
            val,
            curr,
            lang,
            ...marketingParams
        };

        // Construct the URL for the Lambda function with payType
        const lambdaUrl = 'https://tpd43tv7t5uxvba2ftqpcd6bkq0brqjm.lambda-url.us-east-1.on.aws/';
        // https://tpd43tv7t5uxvba2ftqpcd6bkq0brqjm.lambda-url.us-east-1.on.aws/
        // https://k7xykeyqti6n3o4qhzmfjdn7w40kjuks.lambda-url.us-east-1.on.aws/';
        const lambdaQueryString = new URLSearchParams(Object.fromEntries(
            Object.entries(lambdaQueryParams).filter(([_, value]) => value != null)
        )).toString();

        const fullLambdaUrl = lambdaQueryString ? `${lambdaUrl}?${lambdaQueryString}` : lambdaUrl;


        fetch(fullLambdaUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                // Redirect to the URL received in the response, without payType in the query params
                if (data.url) {
                    const redirectUrl = new URL(data.url);
                    // Append only the marketing params to the redirect URL
                    Object.entries(marketingParams).forEach(([key, value]) => {
                        if (value) {
                            redirectUrl.searchParams.append(key, value);
                        }
                    });
                    window.top.location.href = redirectUrl.toString();
                } else {
                    console.error('No redirect URL received');
                }
            })
            .catch(error => {
                console.error('Error fetching from Lambda:', error);
            });
    }, [searchParams, navigate, location]);

    return (
        <>
            <Helmet>
                <title>Pay {value}</title>
                <meta name="description" content={`Pay redirect to Stripe (${value})`}/>
            </Helmet>
            <div className="loader-container">
                <div className="loader"></div>
                <p>Redirecting...</p>
            </div>
        </>
    );
};

export default Pay;
