import { useLocation, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { adjustIframeHeight } from "../HomePage/HomePage";

export const VADemo = () => {
    const location = useLocation();
    const params = useParams();
    const queryParams = new URLSearchParams(location.search);

    // Check if we're using the new URL pattern
    const isNewUrlPattern = location.pathname.startsWith('/demo/');

    // Get botId from either the URL parameter or query parameter
    const botId = isNewUrlPattern ? params.id : (queryParams.get('v') || '-');

    const language = queryParams.get('l') || 'en';
    const background = queryParams.get('b') || 'e4d9ec';
    const textColorTitle = queryParams.get('t1') || '0A1172';
    const textColorSubtitle = queryParams.get('t2') || '4A4A4A';
    const company = queryParams.get('c') || '';
    const url = queryParams.get('url') || undefined;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://app.simplebotinstall.com/js/chat_plugin_ghl.js`;
        script.setAttribute('data-bot-id', botId);
        document.body.appendChild(script);

        // Cleanup
        return () => {
            document.body.removeChild(script);
        };
    }, [botId]);

    useEffect(() => {
        window.addEventListener('resize', adjustIframeHeight);
        adjustIframeHeight();
        return () => window.removeEventListener('resize', adjustIframeHeight);
    }, []);

    const handleIframeLoad = () => {
        adjustIframeHeight();
    };

    return (
        <>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>{language === 'lt' ? 'Virtualaus Asistento demonstracija' : 'Virtual Assistant Demo'}</title>
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
                <style type="text/css">{`
                    body {
                        background-color: #${background};
                        font-family: 'Roboto', sans-serif;
                    }
                `}</style>
            </Helmet>
            {
                url ? (
                    <div style={{width: '100%', height: '100vh', position: 'relative'}}>
                        <iframe
                            id="skydis"
                            src={`https://${url}`}
                            title={language === 'lt' ? `${company} virtualaus asistento demonstracija` : `${company} Virtual Assistant Demo`}
                            style={{width: '100%', height: '100%', border: 'none'}}
                            onLoad={handleIframeLoad}
                        ></iframe>
                    </div>
                ) : (
                    <div>
                        <h1 style={{color: `#${textColorTitle}`, fontSize: '32px', textAlign: 'center'}}>
                            {language === 'lt' ? `${company} virtualaus asistento demonstracija` : `${company} Virtual Assistant Demo`}
                        </h1>
                        <h2 style={{color: `#${textColorSubtitle}`, fontSize: '24px', textAlign: 'center'}}>
                            {language === 'lt' ?
                                'Paspauskite ant rutuliuko tinklapio apačioje ir pradėkite pokalbį' :
                                'Click on the chat circle at the bottom of the screen to start a conversation and test how it\'s working.'}
                        </h2>
                    </div>
                )
            }
        </>
    );
};