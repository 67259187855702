import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function Redirector() {
    const { url } = useParams();
    const [showLink, setShowLink] = useState(false);
    const [decodedUrl, setDecodedUrl] = useState("");

    useEffect(() => {
        const decoded = atob(url);
        setDecodedUrl(decoded);

        // Retrieving marketing data from localStorage
        const fbclid = localStorage.getItem('fbclid');
        const utm_source = localStorage.getItem('utm_source');
        const utm_medium = localStorage.getItem('utm_medium');
        const utm_campaign = localStorage.getItem('utm_campaign');
        const utm_term = localStorage.getItem('utm_term');
        const utm_content = localStorage.getItem('utm_content');

        // Prepare the URL with the parameters
        let redirectUrl = decoded;
        const params = new URLSearchParams();

        if (fbclid) params.append('fbclid', fbclid);
        if (utm_source) params.append('utm_source', utm_source);
        if (utm_medium) params.append('utm_medium', utm_medium);
        if (utm_campaign) params.append('utm_campaign', utm_campaign);
        if (utm_term) params.append('utm_term', utm_term);
        if (utm_content) params.append('utm_content', utm_content);

        if (params.toString()) {
            redirectUrl += (redirectUrl.includes('?') ? '&' : '?') + params.toString();
        }

        // Logging to the data layer before redirecting
        const parentDataLayer = window.parent.dataLayer || [];
        parentDataLayer.push({
            'event': 'redirect_event',
            'redirect_to': redirectUrl,
            'fbclid': fbclid,
            'utm_source': utm_source,
            'utm_medium': utm_medium,
            'utm_campaign': utm_campaign,
            'utm_term': utm_term,
            'utm_content': utm_content
        });

        setTimeout(() => {
            window.top.location.href = redirectUrl;
        }, 100); // Slightly delayed redirect

        setTimeout(() => {
            setShowLink(true);
        }, 6000); // Show a fallback link if redirect fails

    }, [url]);

    return (
        <div>
            Redirecting... {showLink && `Redirect did not work. Please copy/paste the following link to your browser: \n\n`}<strong>{showLink && decodedUrl}</strong>
        </div>
    );
}
