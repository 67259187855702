import React, {useEffect, useState} from "react";
import axios from "axios";

export const VirtualusAsistentas = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get('/yep.so/p/mb-skydis---virtualus-asistentas');
                setContent(response.data);
            } catch (error) {
                console.error('Error fetching content:', error);
            }
        };
        fetchContent();
    }, []);

    return (
        <div dangerouslySetInnerHTML={{__html: content}}/>
    );
};