import React, { useState } from 'react';
import './InfluencerROICalculator.css';

const InfluencerROICalculator = () => {
    const [inputs, setInputs] = useState({
        followers: 0,
        engagementRate: 0,
        conversionRate: 0,
        costPerPost: 0,
        averageOrderValue: 0,
        numberOfPosts: 1,
    });

    const [roi, setRoi] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs(prevInputs => ({
            ...prevInputs,
            [name]: parseFloat(value) || 0
        }));
    };

    const calculateROI = () => {
        const {
            followers,
            engagementRate,
            conversionRate,
            costPerPost,
            averageOrderValue,
            numberOfPosts
        } = inputs;

        const reach = followers * (engagementRate / 100);
        const conversions = reach * (conversionRate / 100);
        const revenue = conversions * averageOrderValue;
        const totalCost = costPerPost * numberOfPosts;
        const profit = revenue - totalCost;
        const calculatedROI = (profit / totalCost) * 100;

        setRoi(calculatedROI);
    };

    const tooltips = {
        followers: "Enter the total number of followers the influencer has",
        engagementRate: "Enter the average engagement rate (likes, comments, shares) as a percentage",
        conversionRate: "Enter the expected percentage of engaged followers who will make a purchase",
        costPerPost: "Enter the amount you'll pay the influencer for each post",
        averageOrderValue: "Enter the average amount a customer spends when making a purchase",
        numberOfPosts: "Enter the number of posts the influencer will make for your campaign"
    };

    return (
        <div className="influencer-roi-calculator">
            <h2>Influencer ROI Calculator</h2>
            <div className="input-group">
                <label title={tooltips.followers}>
                    Number of Followers:
                    <input
                        type="number"
                        name="followers"
                        value={inputs.followers}
                        onChange={handleInputChange}
                    />
                </label>
            </div>
            <div className="input-group">
                <label title={tooltips.engagementRate}>
                    Engagement Rate (%):
                    <input
                        type="number"
                        name="engagementRate"
                        value={inputs.engagementRate}
                        onChange={handleInputChange}
                        step="0.01"
                    />
                </label>
            </div>
            <div className="input-group">
                <label title={tooltips.conversionRate}>
                    Conversion Rate (%):
                    <input
                        type="number"
                        name="conversionRate"
                        value={inputs.conversionRate}
                        onChange={handleInputChange}
                        step="0.01"
                    />
                </label>
            </div>
            <div className="input-group">
                <label title={tooltips.costPerPost}>
                    Cost per Post ($):
                    <input
                        type="number"
                        name="costPerPost"
                        value={inputs.costPerPost}
                        onChange={handleInputChange}
                        step="0.01"
                    />
                </label>
            </div>
            <div className="input-group">
                <label title={tooltips.averageOrderValue}>
                    Average Order Value ($):
                    <input
                        type="number"
                        name="averageOrderValue"
                        value={inputs.averageOrderValue}
                        onChange={handleInputChange}
                        step="0.01"
                    />
                </label>
            </div>
            <div className="input-group">
                <label title={tooltips.numberOfPosts}>
                    Number of Posts:
                    <input
                        type="number"
                        name="numberOfPosts"
                        value={inputs.numberOfPosts}
                        onChange={handleInputChange}
                        min="1"
                    />
                </label>
            </div>
            <button onClick={calculateROI}>Calculate ROI</button>
            {roi !== null && (
                <div className="result">
                    <h3>Calculated ROI: {roi.toFixed(2)}%</h3>
                    <p>
                        {roi > 0
                            ? "Your influencer marketing campaign is profitable!"
                            : "Your influencer marketing campaign is not profitable."}
                    </p>
                </div>
            )}
            <div className="seo-description">
                <h2>Influencer ROI Calculator: Maximize Your Marketing Impact</h2>
                <p>
                    Our Influencer ROI (Return on Investment) Calculator is a powerful tool designed to help marketers, brands, and businesses optimize their influencer marketing campaigns. By inputting key metrics such as follower count, engagement rate, conversion rate, cost per post, average order value, and the number of posts, you can accurately assess the potential profitability of your influencer partnerships.
                </p>
                <p>
                    Understanding your influencer marketing ROI is crucial in today's digital landscape. This calculator takes the guesswork out of campaign planning by providing a clear, data-driven view of your expected returns. Whether you're working with micro-influencers or celebrity endorsements, our tool helps you make informed decisions to maximize your marketing budget and achieve your business goals.
                </p>
                <p>
                    Key features of our Influencer ROI Calculator include:
                </p>
                <ul>
                    <li>Easy-to-use interface with tooltips for guidance</li>
                    <li>Comprehensive metric inputs for accurate calculations</li>
                    <li>Instant ROI results with profitability assessment</li>
                    <li>Adaptable for various campaign sizes and influencer tiers</li>
                </ul>
                <p>
                    By regularly using this calculator, you can fine-tune your influencer marketing strategy, compare different campaign scenarios, and ensure that your investments are driving real business results. Start optimizing your influencer partnerships today with our Influencer ROI Calculator!
                </p>
            </div>
        </div>
    );
};

export default InfluencerROICalculator;