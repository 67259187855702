import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import './LimitedTimeOffer.css';

const adjustIframeHeight = () => {
    // Directly setting the style of the iframe by targeting its title for simplicity
    const iframe = document.querySelector('iframe[id="skydis"]');
    if (iframe) {
        // This adjusts the height to the inner height of the window, accounting for mobile toolbars
        iframe.style.height = `${window.innerHeight}px`;
    }
};

export const LimitedTimeOffer = () => {
    const [isLoading, setIsLoading] = useState(true); // State to manage loading

    useEffect(() => {
        // Listen for resize events to adjust the iframe height accordingly
        window.addEventListener('resize', adjustIframeHeight);

        // Initial adjustment when the component mounts
        adjustIframeHeight();

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', adjustIframeHeight);
    }, []);

    const handleIframeLoad = () => {
        setIsLoading(false); // Set loading to false when iframe is loaded
        adjustIframeHeight(); // Adjust height right after it loads
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <link rel="icon" type="image/png"
                      href="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1641938162703x160586947454074660%2Fyep-icon-2%25402x.png?w=128&h=&auto=compress&dpr=1&fit=max"/>
                <meta name="description" content="Save Time &amp; Boost Your Sales with AI Virtual Assistant"/>
                <meta property="og:title" content="MB Skydis AI Virtual Assistant"/>
                <meta name="twitter:title" content="MB Skydis AI Virtual Assistant"/>
                <meta property="og:description" content="Save Time &amp; Boost Your Sales with AI Virtual Assistant"/>
                <meta name="twitter:description" content="Save Time &amp; Boost Your Sales with AI Virtual Assistant"/>
                <link rel="image_src"
                      href="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1712740453661x378802101663805250%2Fsocialmedia-1712730803594x685802368366280700.png?w=&h=&auto=compress&dpr=1&fit=max"/>
                <meta property="og:image"
                      content="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1712740453661x378802101663805250%2Fsocialmedia-1712730803594x685802368366280700.png?w=&amp;h=&amp;auto=compress&amp;dpr=1&amp;fit=max"/>
                <meta name="twitter:image:src"
                      content="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F35c5b4694ac029c498000f03be8c4832.cdn.bubble.io%2Ff1712740453661x378802101663805250%2Fsocialmedia-1712730803594x685802368366280700.png?w=&amp;h=&amp;auto=compress&amp;dpr=1&amp;fit=max"/>
                <meta property="og:url" content="https://mbskydis.com/mb-skydis-virtual-assistant"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div style={{width: '100%', height: '89vh', position: 'relative'}}>
                {isLoading && (
                    <div className="loader-container">
                        <div className="loader"></div>
                        <p>Loading...</p>
                    </div>
                )}
                <iframe
                    id="skydis"
                    src="https://yep.so/p/mb-skydis-limted-time-offer"
                    title="MB Skydis AI Virtual Assistant"
                    style={{width: '100%', height: '89%', border: 'none'}}
                    onLoad={handleIframeLoad}
                ></iframe>
            </div>
        </>
    );
};